<template>
    <v-container fluid pa-0>
        <bdt-gdpr-c></bdt-gdpr-c>
    </v-container>
  </template>
  
  <script>
    export default
    {
      mounted() { window.scrollTo(0,0); }
    }
  </script>