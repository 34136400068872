<template>
	<v-container class="bg-surface-variant">
		<v-form ref="frm" lazy-validation>
			<v-row no-gutters>
				<v-col cols="12" class="text-center">
					<h2>{{ $t('makeReservation') }}</h2>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded clearable :label="$t('reservationName')" :rules="[ v => !!v || this.$t('resNameEmpty') ]" v-model="name"></v-text-field>
				</v-col>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded clearable :label="$t('reservationMail')" :rules="[mailRule]" placeholder="username@gmail.com" v-model="mail"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationLocationFrom')" :rules="[ v => !!v || this.$t('resLocationsEmpty') ]" v-model="from"></v-text-field>
				</v-col>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationLocationTo')" :rules="[ v => !!v || this.$t('resLocationsEmpty') ]" v-model="to"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationAdults')" :rules="[ v => (!!v || v === '0') || this.$t('resAdultsInvalid') ]" type="number" v-model="adults"></v-text-field>
				</v-col>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationKids')" :rules="[numberRule]" type="number" min="0" v-model="children"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationFrom')" :rules="[ v => !!v || this.$t('resWhenEmpty') ]" v-model="beginDateTime" @click.stop="dateTimeDialogOpen = true"></v-text-field>
				</v-col>
				<v-col cols="12" md="6" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('reservationPhone')" :rules="[phoneRule]" v-model="phoneNo"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="ma-2 pa-2">
				<v-col cols="6" style="margin: 0 auto;">
					<v-btn rounded outlined dense block class="primary" :loading="loading" @click="saveReservation()">{{ $t('reservationButtonText') }}</v-btn>
				</v-col>
			</v-row>
		</v-form>
		<v-dialog v-model="dateTimeDialogOpen" max-width="350px">
			<v-stepper v-model="currentStep">
				<v-stepper-header>
					<v-stepper-step :editable="true" step="1">{{ $t('date') }}</v-stepper-step>
					<v-divider></v-divider>
					<v-stepper-step :editable="true" step="2">{{ $t('time') }}</v-stepper-step>
				</v-stepper-header>
				<v-stepper-items>
					<v-stepper-content step="1">
						<v-card class="mb-12" color="transparent" style="box-shadow: none">
							<v-container>
								<v-row justify="center">
									<v-date-picker :full-width="true" :no-title="true" v-model="beginDate"></v-date-picker>
								</v-row>
								<v-row justify="center" class="pa-2">
									<v-btn color="primary" @click="currentStep = 2">{{ $t('continue') }}</v-btn>
								</v-row>
							</v-container>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="2">
						<v-card class="mb-12" color="transparent" style="box-shadow: none">
							<v-container>
								<v-row justify="center">
									<v-time-picker :full-width="true" :no-title="true" format="24hr" v-model="beginTime"></v-time-picker>
								</v-row>
								<v-row justify="center" class="pa-2">
									<v-btn color="primary" @click="submitDateTime()">{{ $t('finish') }}</v-btn>
								</v-row>
							</v-container>
						</v-card>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-dialog>
		<v-snackbar tile v-model="snackBarOpen" style="position: absolute;">
			{{ snackBarText }}
			<template v-slot:action="{ attrs }">
				<v-btn color="red" width="10" v-bind="attrs" @click="snackBarOpen = false">✕</v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<style>
	.linkNoDecoration { text-decoration: none; }
</style>

<script>
	import moment from 'moment';

	export default
	{
		data: () => ({
			name: '',
			mail: '',
			from: '',
			to: '',
			beginDate: '',
			beginTime: '',
			beginDateTime: '',
			adults: '',
			children: '',
			phoneNo: '',
			loading: false,
			dateTimeDialogOpen: false,
			currentStep: 1,
			snackBarOpen: false,
			snackBarText: '',
			numberRule: () => true,
			dateTimeRule: () => true,
			mailRule: () => true,
			phoneRule: () => true
		}),
		mounted()
		{
			this.numberRule = v =>
			{
				if (v !== null && v !== undefined && !v.trim())
					return true;
				if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999)
					return true;
				return this.$t('errorMsg');
			};
			this.dateTimeRule = v =>
			{
				if (v !== '' && moment(v, 'DD-MM-YYYY HH:mm').isValid())
					return true;
				return this.$t('errorMsg');
			};
			this.mailRule = v =>
			{
				if (v !== '' && this.$utils.Validate.mail(v))
					return true;
				return this.$t('contactMailInvalid');
			};
			this.phoneRule = v =>
			{
				if (v !== '' && this.$utils.Validate.phone(v))
					return true;
				return this.$t('resPhoneInvalid');
			};
		},
		methods:
		{
			saveReservation()
			{
				if (!this.$refs.frm.validate())
					return;
				if (this.beginDateTime === '')
				{
					this.snackBarOpen = true;
					this.snackBarText = this.$t('resWhenEmpty');
					return;
				}
				
				this.loading = true;
				let rsv =
				{
					name: this.name,
					fromLocation: this.from,
					toLocation: this.to,
					beginDateTime: moment(this.beginDateTime).format('DD-MM-YYYY HH:mm:ss'),
					adults: parseInt(this.adults),
					children: this.children === '' ? 0 : parseInt(this.children),
					mailAddress: this.mail,
					phoneNo: this.phoneNo
				};
				this.$utils
					.postRequest({ url: window.atob(this.$utils.ep.r), dataToSend: rsv })
					.then(() =>
					{
						this.loading = false;
						this.snackBarOpen = true;
						this.snackBarText = this.$t('reservationSuccess');
						this.$refs.frm.reset();
					});
			},
			submitDateTime()
			{
				this.dateTimeDialogOpen = false;
				let bd = this.beginDate === '' ? '00-00-0000' : this.beginDate;
				let bt = this.beginTime === '' ? '00:00' : this.beginTime;
				this.beginDateTime = `${bd} ${bt}`;
			},
			formatDate(date)
			{
				if (!date)
					return null

				const [year, month, day] = date.split('-')
				return `${day}-${month}-${year}`
			}
		}
	}
</script>
