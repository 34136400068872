import VuetifyConfig from "./vuetifyConfig";
import Localizer from "./localizer";
import Router from "./router";
import Utils from "./utils";

const Tools =
{
	Localizer,
	Router,
	Utils,
	VuetifyConfig
};

export default Tools;