// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Jura);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Comfortaa);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainView{margin-top:50px;margin-bottom:320px;font-family:Jura,Comfortaa,monospace!important}.v-btn{text-transform:unset!important}@media screen and (max-width:960px){.mainView{margin-bottom:480px}}::-webkit-scrollbar{width:8px}::-webkit-scrollbar-track{background:#000}::-webkit-scrollbar-thumb:hover{background:hsla(0,0%,100%,.5)}::-webkit-scrollbar-thumb{background:#fff;border-radius:100px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
