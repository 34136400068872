<template>
	<v-layout>
		<v-flex xs12 md12>
			<v-carousel cycle hide-delimiters show-arrows-on-hover :height="sliderHeight">
				<v-carousel-item v-for="(item, i) in sliderItems" :key="i" :src="item.src">
					<v-sheet>
						<p class="sliderTitle">{{ item.title }}</p>
						<v-btn outlined light small class="float-right" @click="item.btnAction()">{{ item.btnTitle }}</v-btn>
					</v-sheet>
				</v-carousel-item>
			</v-carousel>
		</v-flex>
	</v-layout>
</template>

<style scoped>
	.sliderTitle { font-size: 24px; font-weight: bold; }
	.v-sheet
	{
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
		color: rgba(255, 255, 255, 0.75);
		position: absolute;
		bottom: 0;
		width: 80%;
		height: 14%;
		margin: 10%;
		padding: 20px;
		border-radius: 5px;
	}
	.v-btn
	{
		position: absolute;
		bottom: 20px;
		right: 20px;
		border-color: rgba(255, 255, 255, 0.5);
		color: rgba(255, 255, 255, 0.5);
	}

	@media screen and (max-width: 1400px) { .v-sheet { height: 14%; } }
	@media screen and (max-width: 1200px)
	{
		.v-sheet
		{
			width: 85%;
			margin: 7.5%;
		}
		.sliderTitle { font-size: 20px; }
	}
	@media screen and (max-width: 1000px) { .sliderTitle { font-size: 18px; } }
	@media screen and (max-width: 700px)
	{
		.v-sheet { height: 18%; }
		.sliderTitle { font-size: 16px; }
	}
	@media screen and (max-width: 400px)
	{
		.sliderTitle { font-size: 14px; }
		.v-sheet
		{
			width: 90%;
			margin: 5%;
		}
	}
	@media screen and (max-width: 200px) { .v-sheet { height: 26%; } }
</style>

<script>
	import S1 from '../../assets/s/s_1.jpg';
	import S2 from '../../assets/s/s_2.jpg';
	import S3 from '../../assets/s/s_3.jpg';
	import S4 from '../../assets/s/s_4.jpg';
	export default
	{
		data: () => ({
			sliderHeight: window.screen.height - 180,
			sliderItems: []
		}),
		mounted()
		{
			window.addEventListener('resize', () => this.sliderHeight = window.screen.height - 180);

			[
				{
					src: S1,
					title: this.$t('slide_1_title'),
					btnTitle: this.$t('slide_1_button'),
					btnAction: () => this.$router.push('/services')
				},
				{
					src: S2,
					title: this.$t('slide_3_title'),
					btnTitle: this.$t('slide_3_button'),
					btnAction: () => this.$router.push('/reservation')
				},
				{
					src: S3,
					title: this.$t('slide_2_title'),
					btnTitle: this.$t('slide_2_button'),
					btnAction: () => this.$router.push('/services')
				},
				{
					src: S4,
					title: this.$t('slide_4_title'),
					btnTitle: this.$t('slide_4_button'),
					btnAction: () => this.$router.push('/contact')
				}
			].forEach(si => this.sliderItems.push(si));
		}
	}
</script>
