<template>
	<v-container class="bg-surface-variant">
		<v-card class="d-inline-block mx-auto">
			<v-container class="bg-surface-variant">
				<!-- GENERICS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>{{ $t('clientServicesTct_title') }}</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_generic') }}</p>
					</v-col>
				</v-row>
				<!-- E-RESERVATIONS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('clientServicesTct_eReservationTerm_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_generic') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ol>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_1') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_2') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_3') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_4') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_5') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_eReservationTerm_6') }}</li>
						</ol>
					</v-col>
				</v-row>
				<!-- TRANSFER COSTS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_transferCost_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_transferCost') }}</p>
					</v-col>
				</v-row>
				<!-- PAYMENTS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_paymentsTerm_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ol>
							<li style="text-align: justify;">{{ $t('clientServicesTct_paymentsTerm_1') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_paymentsTerm_2') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_paymentsTerm_3') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_paymentsTerm_4') }}</li>
						</ol>
					</v-col>
				</v-row>
				<!-- CANCELLATIONS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_cancellationsTerm_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ol>
							<li style="text-align: justify;">{{ $t('clientServicesTct_cancellationsTerm_1') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_cancellationsTerm_2') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_cancellationsTerm_3') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_cancellationsTerm_4') }}</li>
							<li style="text-align: justify;">{{ $t('clientServicesTct_cancellationsTerm_5') }}</li>
						</ol>
					</v-col>
				</v-row>
				<!-- SAFETY -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_safety_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_safety') }}</p>
					</v-col>
				</v-row>
				<!-- LATE ARRIVALS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_lateArrivals_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_lateArrivals') }}</p>
					</v-col>
				</v-row>
				<!-- LUGGAGE -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_luggage_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_luggage') }}</p>
					</v-col>
				</v-row>
				<!-- CONTACT -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic; margin-top: 10px;">{{ $t('clientServicesTct_contactUs_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('clientServicesTct_contactUs') }}</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-card class="d-inline-block mx-auto mt-4">
			<v-container class="bg-surface-variant">
				<!-- GENERICS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>{{ $t('webSiteTct_title') }}</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 1 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_1_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_1') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 2 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_2_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_2') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 3 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_3_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_3') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 4 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_4_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_4') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 5 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_5_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_5') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 6 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_6_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ol>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_1') }}</li>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_2') }}</li>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_3') }}</li>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_4') }}</li>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_5') }}</li>
							<li style="text-align: justify;">{{ $t('webSiteTct_term_6_sub_6') }}</li>
						</ol>
					</v-col>
				</v-row>
				<!-- TERM 7 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_7_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_7') }}</p>
					</v-col>
				</v-row>
				<!-- TERM 8 -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('webSiteTct_term_8_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('webSiteTct_term_8') }}</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-card class="d-inline-block mx-auto mt-4">
			<v-container class="bg-surface-variant">
				<!-- COOKIES -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>Cookies</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('cookiesTct_1') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('cookiesTct_2') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('cookiesTct_3') }}</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-card class="d-inline-block mx-auto mt-4">
			<v-container class="bg-surface-variant">
				<!-- GENERICS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>{{ $t('privacyTct_title') }}</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct') }}</p>
					</v-col>
				</v-row>
				<!-- DATA COLLECTION -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_dataCollection_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_dataCollection') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ol>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_1') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_2') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_3') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_4') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_5') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_6') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_dataCollection_sub_7') }}</li>
						</ol>
					</v-col>
				</v-row>
				<!-- PERSONAL DATA -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center mt-4">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_personalData_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_personalData') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_personalData_mail') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ul>
							<li style="text-align: justify;">{{ $t('privacyTct_personalData_mail_sub_1') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalData_mail_sub_2') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalData_mail_sub_3') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalData_mail_sub_4') }}</li>
						</ul>
					</v-col>
				</v-row>
				<v-row no-gutters class="mt-4">
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_personalData_mail_foot') }}</p>
					</v-col>
				</v-row>
				<!-- PERSONAL DATA USAGE -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center mt-4">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_personalDataUsage_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_personalDataUsage') }}</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ul>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_1') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_2') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_3') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_4') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_5') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_6') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_7') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_8') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_9') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_10') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_11') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_12') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_13') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_use_14') }}</li>
						</ul>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center mt-4">
						<p style="text-align: justify;">{{ $t('privacyTct_personalDataUsage_foot') }}</p>
					</v-col>
				</v-row>
				<!-- USER PROFILES -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_userProfiles_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_userProfiles') }}</p>
					</v-col>
				</v-row>
				<!-- IP -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_ip_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_ip') }}</p>
					</v-col>
				</v-row>
				<!-- PAYMENTS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_payments_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('privacyTct_payments') }}</p>
					</v-col>
				</v-row>
				<!-- RIGHTS -->
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h3 style="text-align: justify; font-style: italic;">{{ $t('privacyTct_rights_title') }}</h3>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<ul>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_1') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_2') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_3') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_4') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_5') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_6') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_7') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_8') }}</li>
							<li style="text-align: justify;">{{ $t('privacyTct_rights_9') }}</li>
						</ul>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-container>
</template>

<script>
	export default {}
</script>
