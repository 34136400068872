<template>
	<v-container class="bg-surface-variant">
		<v-card class="d-inline-block mx-auto">
			<v-container class="bg-surface-variant">
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>{{ $t('bannerTitle') }}</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('aboutUsText') }}</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-container>
</template>

<script>
	export default {}
</script>
