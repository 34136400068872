<template>
    <v-container class="bg-surface-variant text-center">
        <v-card class="d-inline-block mx-auto">
			<v-container class="bg-surface-variant">
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<h1>{{ $t('gallery') }}</h1>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12" class="text-center">
						<p style="text-align: justify;">{{ $t('servicesText') }}</p>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-card class="d-inline-block ma-2 mx-auto">
			<v-container class="bg-surface-variant">
				<v-row>
					<v-col cols="6" class="text-center">
						<v-hover>
							<template v-slot:default="{ hover }">
								<v-card class="mx-auto" max-width="344">
									<v-img src="../../assets/g_1.jpg" height="200px"></v-img>
									<v-card-title>Top western road trips</v-card-title>
									<v-card-subtitle style="text-align: start;">1,000 miles of wonder</v-card-subtitle>
									<v-fade-transition>
										<v-overlay v-if="hover" absolute>
											<v-btn fab small @click="overlay_1 = true"><v-icon dark>mdi-eye</v-icon></v-btn>
										</v-overlay>
									</v-fade-transition>
									<v-overlay :value="overlay_1">
										<v-btn fab small color="error" style="float: right; margin-top: -30px;" @click="overlay_1 = false"><v-icon dark>mdi-close</v-icon></v-btn>
										<v-img contain src="../../assets/g_1.jpg" :style="i1Style"></v-img>
									</v-overlay>
								</v-card>
							</template>
						</v-hover>
					</v-col>
					<v-col cols="6" class="text-center">
						<v-hover>
							<template v-slot:default="{ hover }">
								<v-card class="mx-auto" max-width="344">
									<v-img src="../../assets/g_2.jpg" height="200px"></v-img>
									<v-card-title>Top western road trips</v-card-title>
									<v-card-subtitle style="text-align: start;">1,000 miles of wonder</v-card-subtitle>
									<v-fade-transition>
										<v-overlay v-if="hover" absolute>
											<v-btn fab small @click="overlay_2 = true"><v-icon dark>mdi-eye</v-icon></v-btn>
										</v-overlay>
									</v-fade-transition>
									<v-overlay :value="overlay_2">
										<v-btn fab small color="error" style="float: right; margin-top: -30px;" @click="overlay_2 = false"><v-icon dark>mdi-close</v-icon></v-btn>
										<v-img contain src="../../assets/g_2.jpg" :style="i2Style"></v-img>
									</v-overlay>
								</v-card>
							</template>
						</v-hover>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6" class="text-center">
						<v-hover>
							<template v-slot:default="{ hover }">
								<v-card class="mx-auto" max-width="344">
									<v-img src="../../assets/g_3.jpg" height="200px"></v-img>
									<v-card-title>Top western road trips</v-card-title>
									<v-card-subtitle style="text-align: start;">1,000 miles of wonder</v-card-subtitle>
									<v-fade-transition>
										<v-overlay v-if="hover" absolute>
											<v-btn fab small @click="overlay_3 = true"><v-icon dark>mdi-eye</v-icon></v-btn>
										</v-overlay>
									</v-fade-transition>
									<v-overlay :value="overlay_3">
										<v-btn fab small color="error" style="float: right; margin-top: -30px;" @click="overlay_3 = false"><v-icon dark>mdi-close</v-icon></v-btn>
										<v-img contain src="../../assets/g_3.jpg"></v-img>
									</v-overlay>
								</v-card>
							</template>
						</v-hover>
					</v-col>
					<v-col cols="6" class="text-center">
						<v-hover>
							<template v-slot:default="{ hover }">
								<v-card class="mx-auto" max-width="344">
									<v-img src="../../assets/g_4.jpg" height="200px"></v-img>
									<v-card-title>Top western road trips</v-card-title>
									<v-card-subtitle style="text-align: start;">1,000 miles of wonder</v-card-subtitle>
									<v-fade-transition>
										<v-overlay v-if="hover" absolute>
											<v-btn fab small @click="overlay_4 = true"><v-icon dark>mdi-eye</v-icon></v-btn>
										</v-overlay>
									</v-fade-transition>
									<v-overlay :value="overlay_4">
										<v-btn fab small color="error" style="float: right; margin-top: -30px;" @click="overlay_4 = false"><v-icon dark>mdi-close</v-icon></v-btn>
										<v-img src="../../assets/g_4.jpg"></v-img>
									</v-overlay>
								</v-card>
							</template>
						</v-hover>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
    </v-container>
</template>
  
<script>
    export default
    {
		data: () => ({
			i1_h: window.innerHeight - 20,
			overlay_1: false,
			overlay_2: false,
			overlay_3: false,
			overlay_4: false
		}),
		computed:
		{
			i1Style() { return `height: ${window.innerHeight - 100}px;`; },
			i2Style() { return `height: ${window.innerHeight - 100}px;`; }
		},
        mounted() { window.scrollTo(0, 0); }
    }
</script>