<template>
	<v-container class="bg-surface-variant">
		<v-form ref="frm" lazy-validation>
			<v-row no-gutters>
				<v-col cols="12" class="text-center">
					<h2>{{ $t('contactUsFormTitle') }}</h2>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('contactName')" :rules="[ v => !!v || this.$t('contactNameEmpty') ]" v-model="name"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" class="pa-1">
					<v-text-field outlined dense rounded :label="$t('contactMail')" :rules="[ v => !!v || this.$t('contactMailEmpty'), v => this.$utils.Validate.mail(v) || this.$t('contactMailInvalid') ]" v-model="mail"></v-text-field>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12" class="pa-1">
					<v-textarea clearable outlined dense rounded no-resize :label="$t('contactText')" :rules="[ v => !!v || this.$t('contactTextEmpty') ]" v-model="message"></v-textarea>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="6" class="pa-1" style="margin: 0 auto;">
					<v-btn rounded titlecase outlined block class="primary" :loading="loading" @click="sendMessage()">{{ $t('contactButton') }}</v-btn>
				</v-col>
			</v-row>
		</v-form>
		<v-snackbar tile v-model="snackBarOpen">
			{{ snackBarText }}
			<template v-slot:action="{ attrs }">
				<v-btn color="red" width="10" v-bind="attrs" @click="snackBarOpen = false">✕</v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<style>
	.linkNoDecoration { text-decoration: none; }
</style>

<script>
	export default
	{
		data: () => ({
			name: '',
			mail: '',
			message: '',
			loading: false,
			snackBarOpen: false,
			snackBarText: ''
		}),
		methods:
		{
			sendMessage()
			{
				if (!this.$refs.frm.validate())
					return;
				
				this.loading = true;
				let msg = { name: this.name, mailAddress: this.mail, text: this.message };
				this.$utils
					.postRequest({ url: window.atob(this.$utils.ep.m), dataToSend: msg })
					.then(() =>
					{
						this.loading = false;
						this.snackBarOpen = true;
						this.snackBarText = this.$t('contactMessageSentSuccess');
						this.$refs.frm.reset();
					});
			}
		}
	}
</script>
