<template>
    <v-snackbar tile :timeout="-1" v-model="gdprSnackBarOpen" style="position: fixed;">
        <p style="text-align: justify;">{{ gdprSnackBarText }}</p>
        <v-dialog v-model="dialog" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" style="width: 100%;" dark v-bind="attrs" v-on="on">{{ $t('cookieInfo') }}</v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5">Cookies</v-card-title>
                <v-card-text>
                    <p style="text-align: justify;">{{ $t('cookiesTct_1') }}</p>
                    <p style="text-align: justify;">{{ $t('cookiesTct_2') }}</p>
                    <p style="text-align: justify;">{{ $t('cookiesTct_3') }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">{{ $t('okText') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <template v-slot:action="{ attrs }">
            <v-btn color="red" style="margin: 0px 5px" v-bind="attrs" @click="setGdprResponse('reject')">{{ $t('reject') }}</v-btn>
            <v-btn color="green" style="margin: 0px 5px" v-bind="attrs" @click="setGdprResponse('accept')">{{ $t('accept') }}</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import VueCookies from 'vue-cookie';

    export default
    {
        data: () => ({
            gdprSnackBarOpen: true,
            gdprSnackBarText: '',
            dialog: false
        }),
        mounted() {
            this.gdprSnackBarText = this.$t('gdprText');
            let gdpr = VueCookies.get('gdpr');
            this.gdprSnackBarOpen = gdpr === null;
            window.scrollTo(0, 0);
        },
        methods:
        {
            setGdprResponse(r)
            {
                VueCookies.set('gdpr', r, (60 * 1000));
                this.gdprSnackBarOpen = false;
            }
        }
    }
</script>
