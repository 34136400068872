import VueRouter from 'vue-router';
import Views from '../views';
import Vue from 'vue';

Vue.use(VueRouter);

const Router = new VueRouter(
{
  mode: 'history',
  base: process.env.BASE_URL,
  routes:
  [
    { path: '/', name: 'home', component: Views.Home },
    { path: '/lang/:lang', name: 'lang', component: Views.Home },
    { path: '/contact', name: 'contact', component: Views.Contact },
    { path: '/services', name: 'services', component: Views.Services },
    { path: '/reservation', name: 'reservation', component: Views.Reservation },
    { path: '/gallery', name: 'gallery', component: Views.Gallery },
    { path: '/gdpr', name: 'gdpr', component: Views.Gdpr }
  ]
});

export default Router;