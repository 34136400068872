import Tools from './tools';
import Views from './views';
import Vue from 'vue';

Vue.prototype.$utils = Tools.Utils;
Vue.config.productionTip = false;

Vue.component('bdt-gdpr-snack', Views.Components.GdprSnackComponent);
Vue.component('bdt-rf-bg', Views.Components.ReservationFormBg);
Vue.component('bdt-sx', Views.Components.ServicesExpandable);
Vue.component('bdt-gdpr-c', Views.Components.GdprComponent);
Vue.component('bdt-rf', Views.Components.ReservationForm);
Vue.component('bdt-sc', Views.Components.ServicesCaption);
Vue.component('bdt-cc', Views.Components.ContactCaption);
Vue.component('bdt-ac', Views.Components.AboutCaption);
Vue.component('bdt-c', Views.Components.ContactForm);
Vue.component('bdt-h', Views.Components.Header);
Vue.component('bdt-f', Views.Components.Footer);
Vue.component('bdt-s', Views.Components.Slider);
Vue.component('bdt-fab', Views.Components.Fab);

new Vue(
{
  i18n: Tools.Localizer,
  router: Tools.Router,
  vuetify: Tools.VuetifyConfig,
  render: h => h(Views.App)
}).$mount('#app');