<template>
	<v-container fluid class="ma-0 pa-0">
		<v-app-bar fixed dense class="appBarStyle">
			<v-app-bar-nav-icon color="black" @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>
			<img src="https://www.aetoitonmetaforon.eu/images/medals/53920/laureat300_gold_gr.png" style="width: 160px; cursor: pointer;" class="showHide" @click="awardClicked()"/>
			<v-spacer></v-spacer>
			<v-toolbar-title>
				<img :src="headerIcon" class="headerIcon" @click="routeItemClicked('/')"/>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-spacer class="showHide"></v-spacer>
			<v-spacer></v-spacer>
			<v-menu left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon color="green">mdi-web</v-icon>
					</v-btn>
				</template>
				<v-list flat>
					<v-list-item-group v-for="l in languages" :key="l.id">
						<v-list-item @click="languageSelected(l.id)">
							<v-list-item-icon>
								<v-img :src="l.icon"></v-img>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ l.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer v-model="drawerOpen" fixed bottom temporary>
			<v-list nav dense>
				<v-list-item-group active-class="text--accent-4">
					<v-list-item v-for="route in this.$router.options.routes.filter(x => x.name !== 'lang')" :key="route.path" @click="routeItemClicked(route.path)">
						<v-list-item-title>{{ $t(route.name) }}</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</v-container>
</template>

<style scoped>
	.v-list { background: black !important; }
	.headerIcon
	{
		width: 200px;
		margin-top: 8px;
		cursor: pointer;
	}
</style>

<style scoped>
	.v-app-bar { background-color: black !important; border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
	.mdi-menu { color: white !important; }
	.v-navigation-drawer { background-color: black !important; }
	@media screen and (max-width: 960px) { .showHide { display: none; } }
</style>

<script>
	import BdtHorWhiteIcon from '../../assets/bdtHor_White.svg';
	import GrIcon from '../../assets/gr.png';
	import ArIcon from '../../assets/ar.png';
	import EnIcon from '../../assets/en.png';
	import FrIcon from '../../assets/fr.png';
	import ItIcon from '../../assets/it.png';
	import RuIcon from '../../assets/ru.png';
	import SpIcon from '../../assets/sp.png';
	import VueCookies from 'vue-cookie';

	export default
	{
		data: () => ({
			headerIcon: BdtHorWhiteIcon,
			drawerOpen: false,
			themeButtonColor: 'white',
			languages: []
		}),
		mounted()
		{
			[
				{ id: 'en', title: this.$t('en'), icon: EnIcon },
				{ id: 'gr', title: this.$t('gr'), icon: GrIcon },
				{ id: 'ar', title: this.$t('ar'), icon: ArIcon },
				{ id: 'fr', title: this.$t('fr'), icon: FrIcon },
				{ id: 'it', title: this.$t('it'), icon: ItIcon },
				{ id: 'ru', title: this.$t('ru'), icon: RuIcon },
				{ id: 'sp', title: this.$t('sp'), icon: SpIcon }
			].forEach(l => this.languages.push(l));

			let lang = VueCookies.get('locale');
			if (lang != null && lang != '')
				this.$i18n.locale = lang;
		},
		methods:
		{
			toggleDrawer()
			{
				this.drawerOpen = !this.drawerOpen;
			},
			languageSelected(lang)
			{
				VueCookies.set('locale', lang, (60*1000));
				this.$i18n.locale = lang;
				new Promise(r => setTimeout(r, 500))
					.then(() =>
					{
						this.$i18n.locale = lang;
						window.location.reload();
					});
			},
			routeItemClicked(path)
			{
				if (path === window.location.pathname)
				{
					this.drawerOpen = false;
					return;
				}
				this.$router.push(path);
			},
			awardClicked: () => window.open('https://www.aetoitonmetaforon.eu/profile-53920-black-diamond-transfer').focus()
		}
	}
</script>