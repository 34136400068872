<template>
	<div class="fabContainer">
		<v-btn fab dark color="green" class="fab" @click="whatsAppAction()">
			<v-icon>mdi-whatsapp</v-icon>
		</v-btn>
		<v-btn fab dark color="blue" class="fab" @click="callAction()">
			<v-icon>mdi-phone</v-icon>
		</v-btn>
	</div>
</template>

<style scoped>
	.fabContainer
	{
		position: fixed;
		right: 0;
		bottom: 0;
		z-index: 9999;
		padding: 10px;
	}
	.fab { margin: 5px; }
</style>

<script>
	export default
	{
		data: () => ({
			fabOpen: false
		}),
		methods:
		{
			whatsAppAction: () => window.open('https://wa.me/6977863644', '_blank').focus(),
			callAction: () => window.open('tel:+306977863644')
		}
	}
</script>
