<template>
  <v-container fluid pa-0>
    <bdt-cc></bdt-cc>
    <bdt-c></bdt-c>
  </v-container>
</template>

<script>
  export default
  {
    mounted() { window.scrollTo(0,0); }
  }
</script>