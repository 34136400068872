import Axios from 'axios';

const Utils =
{
    ep:
    {
        m: 'aHR0cHM6Ly91cy1jZW50cmFsMS1ibGFjay1kaWFtb25kLXRyYW5zZmVyLmNsb3VkZnVuY3Rpb25zLm5ldC9tZXNzYWdlcw==',
        r: 'aHR0cHM6Ly91cy1jZW50cmFsMS1ibGFjay1kaWFtb25kLXRyYW5zZmVyLmNsb3VkZnVuY3Rpb25zLm5ldC9yZXNlcnZhdGlvbnM='
    },
    getRequest: (params) =>
        Axios.get(params.url, { params: params.dataToSend })
            .then((response) => { return response })
            .catch((error) => { return error }),
    postRequest: (params) =>
        Axios.post(params.url, params.dataToSend)
            .then((response) => { return response })
            .catch((error) => { return error }),
    putRequest: (params) =>
        Axios.put(params.url, params.dataToSend)
            .then((response) => { return response })
            .catch((error) => { return error }),
    deleteRequest: (params) =>
        Axios.delete(params.url, params.dataToSend)
            .then((response) => { return response })
            .catch((error) => { return error }),
    getDateTimeStringFormatted: d =>
    {
        let date = new Date(d);

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        let hours = date.getHours();
        let minutes = `0${date.getMinutes()}`;
        let seconds = `0${date.getSeconds()}`;

        return `${day}/${month}/${year} ${hours}:${minutes.substring(-2)}:${seconds.substring(-2)}`;
    },
    getDateStringFormatted: d =>
    {
        let date = new Date(d);

        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return `${month > 9 ? month : `0${month}`}/${year}`;
    },
    getDateDifferenceInMonths: (dt1, dt2) =>
    {
        let d1 = new Date(dt1);
        let d2 = new Date(dt2);
        let d1y = d1.getFullYear();
        let d2y = d2.getFullYear();
        let d1m = d1.getMonth();
        let d2m = d2.getMonth();

        return (d2m + 12 * d2y) - (d1m + 12 * d1y);
    },
    getRandomColor: () => `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    Validate:
    {
        mail: m => /\S+@\S+\.\S+/.test(m),
        phone: p => /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(p),
        url: url => new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?').test(url),
        date: d => /^\d{2}[./-]\d{2}[./-]\d{4}$/.test(d) || /^\d{4}[./-]\d{2}[./-]\d{2}$/.test(d)
    }
};

export default Utils;