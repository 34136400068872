import ReservationView from './views/ReservationView.vue';
import ServicesView from './views/ServicesView.vue';
import ContactView from './views/ContactView.vue';
import GalleryView from './views/GalleryView';
import HomeView from './views/HomeView.vue';
import GdprView from './views/GdprView.vue';
import Components from './components';
import App from './App.vue';

const Views =
{
	App,
	Gdpr: GdprView,
	Contact: ContactView,
	Home: HomeView,
	Reservation: ReservationView,
	Services: ServicesView,
	Gallery: GalleryView,
	Components
};

export default Views;