import En from './languages/en.json';
import Gr from './languages/gr.json';
import Ar from './languages/ar.json';
import Fr from './languages/fr.json';
import It from './languages/it.json';
import Ru from './languages/ru.json';
import Sp from './languages/sp.json';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

const Localizer = new VueI18n(
{
	locale: 'gr',
	fallbackLocale: 'en',
	messages:
	{
		gr: Gr,
		en: En,
		ar: Ar,
		fr: Fr,
		it: It,
		ru: Ru,
		sp: Sp
	}
});

export default Localizer;