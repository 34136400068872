import { render, staticRenderFns } from "./FloatingActionButtonComponent.vue?vue&type=template&id=1383f63c&scoped=true&"
import script from "./FloatingActionButtonComponent.vue?vue&type=script&lang=js&"
export * from "./FloatingActionButtonComponent.vue?vue&type=script&lang=js&"
import style0 from "./FloatingActionButtonComponent.vue?vue&type=style&index=0&id=1383f63c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1383f63c",
  null
  
)

export default component.exports