<template>
	<v-container fluid pa-0>
		<bdt-s></bdt-s>
		<bdt-rf-bg></bdt-rf-bg>
		<bdt-ac></bdt-ac>
		<bdt-sx></bdt-sx>
		<bdt-fab></bdt-fab>
	</v-container>
</template>

<script>
	import VueCookies from 'vue-cookie';

	export default
	{
		mounted()
		{
			window.scrollTo(0,0);
			
			let lng = this.$route.params.lang;
			if (lng === undefined)
				return;
			if (lng.length !== 2)
			{
				window.location.replace(window.location.origin);
				return;
			}
			
			VueCookies.set('locale', lng, (60*1000));
				this.$i18n.locale = lng;
				new Promise(r => setTimeout(r, 500))
					.then(() =>
					{
						this.$i18n.locale = lng;
						window.location.replace(window.location.origin);
					});
		}
	}
</script>
