<template>
	<v-container class="bg-surface-variant text-center">
		<v-card class="d-inline-block mx-auto">
			<v-container>
				<v-row>
					<v-col cols="12" md="4">
						<v-img class="svgImg" :src="image"></v-img>
					</v-col>
					<v-col cols="12" md="8" style="margin: auto;">
						<v-row class="flex-column ma-0 fill-height" justify="center">
							<v-col class="px-0">
								<v-expansion-panels>
									<v-expansion-panel v-for="svc in svcList" :key="svc.title">
										<v-expansion-panel-header>{{ svc.title }}</v-expansion-panel-header>
										<v-expansion-panel-content>{{ svc.text }}</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-container>
</template>

<style scoped>
	.svgImg { width: 34vh; height: 100%; }
	@media screen and (max-width: 960px) { .svgImg { margin: 0 auto; width: 50vh; } }
</style>

<script>
	import CarImage from '../../assets/i1.webp';

	export default
	{
		data: () => ({
			svcList: [],
			image: CarImage
		}),
		mounted()
		{
			[
				{ title: this.$t('svc_1'), text: this.$t('svc_1_sub') },
				{ title: this.$t('svc_2'), text: this.$t('svc_2_sub') },
				{ title: this.$t('svc_3'), text: this.$t('svc_3_sub') }
			].forEach(svc => this.svcList.push(svc))
		}
	}
</script>
