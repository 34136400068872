<template>
	<v-footer bottom absolute padless>
		<v-card flat tile class="white--text text-center" style="width: 100%;">
			<v-card-text>
				<v-btn icon class="mx-4 white--text" @click="phoneAction()">
					<v-icon size="24px">mdi-phone</v-icon>
				</v-btn>
				<v-btn icon class="mx-4 white--text" @click="igAction()">
					<v-icon size="24px">mdi-instagram</v-icon>
				</v-btn>
				<v-btn icon class="mx-4 white--text" @click="mailAction()">
					<v-icon size="24px">mdi-at</v-icon>
				</v-btn>
			</v-card-text>

			<v-card-text class="white--text pt-0">
				<v-container>
					<v-row no-gutters>
						<v-col cols="12" md="3">
							<v-img :src="logoIcon" class="footerIcon" aspect-ratio="1.7" contain></v-img>
							<p class="mx-auto mt-4 mb-4 termsConditions" @click="routeItemClicked()">{{ $t('termsServices') }}</p>
						</v-col>
						<v-col cols="12" md="3">
							<img src="https://www.aetoitonmetaforon.eu/images/medals/53920/laureat110_gold_2_gr.png" style="cursor: pointer;" @click="awardClicked()"/>
						</v-col>
						<v-col cols="12" md="6" style="margin: auto;">
							<v-card class="mx-auto" outlined style="margin: 10px;">
								<v-card-subtitle>{{ $t('address') }}</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-text class="white--text showHide">
				{{ new Date().getFullYear() }} — <strong>Black Diamond Transfer</strong>
			</v-card-text>
		</v-card>
	</v-footer>
</template>

<style scoped>
	.v-footer { background-color: black !important; }
	.v-chip { cursor: pointer; }
	.footerIcon { width: 100%; height: 10vh; }
	.termsConditions { cursor: pointer; width: 180px; }
	.termsConditions:hover { text-shadow: 0 0 2px rgba(255, 255, 255, 1); }
	@media screen and (max-width: 960px) { .showHide { display: none; } }
</style>

<script>
	import BdtVerWhiteIcon from '../../assets/bdtVer_White.svg';

	export default
	{
		data: () => ({
			logoIcon: BdtVerWhiteIcon
		}),
		methods:
		{
			phoneAction: () => window.open('tel:+306977863644'),
			igAction: () => window.open('https://www.instagram.com/black_diamond_transfer/', '_blank').focus(),
			mailAction: () => window.open('mailto:info@bdtransfer.gr', '_blank').focus(),
			routeItemClicked() { this.$router.push('/gdpr'); },
			awardClicked: () => window.open('https://www.aetoitonmetaforon.eu/profile-53920-black-diamond-transfer').focus()
		}
	}
</script>
