<template>
  <v-app>
    <v-main>
      <bdt-h></bdt-h>
      <v-lazy :options="{ threshold: 0.1 }" min-height="200" transition="fade-transition">
        <router-view class="mainView"/>
      </v-lazy>
      <bdt-f></bdt-f>
      <bdt-gdpr-snack></bdt-gdpr-snack>
    </v-main>
  </v-app>
</template>

<style>
  @import url('https://fonts.googleapis.com/css?family=Jura');
  @import url('https://fonts.googleapis.com/css2?family=Comfortaa');
  .mainView { margin-top: 50px; margin-bottom: 320px; font-family: 'Jura', 'Comfortaa', monospace !important; }
  .v-btn { text-transform: unset !important; }
  @media screen and (max-width: 960px) { .mainView { margin-bottom: 480px; } }
  /* Width */
  ::-webkit-scrollbar { width: 8px; }
  /* Track */
  ::-webkit-scrollbar-track { background: rgba(0, 0, 0, 1); }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover { background: rgba(255, 255, 255, 0.5); }
  /* Handle */
  ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 1); border-radius: 100px; }
</style>

<script>
  export default {}
</script>