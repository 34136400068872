<template>
  <v-container fluid pa-0>
    <bdt-ac></bdt-ac>
    <bdt-rf></bdt-rf>
  </v-container>
</template>

<script>
  export default
  {
    mounted() { window.scrollTo(0,0); }
  }
</script>