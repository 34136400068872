import FloatingActionButtonComponent from './FloatingActionButtonComponent.vue';
import OurServicesCaptionComponent from './OurServicesCaptionComponent.vue';
import ReservationFormBgComponent from './ReservationFormBgComponent.vue';
import ContactUsCaptionComponent from './ContactUsCaptionComponent.vue';
import ReservationFormComponent from './ReservationFormComponent.vue';
import OurServicesExpComponent from './OurServicesExpComponent.vue';
import AboutUsCaptionComponent from './AboutUsCaptionComponent.vue';
import GdprSnackBarComponent from './GdprSnackBarComponent.vue';
import ContactFormComponent from './ContactFormComponent.vue';
import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';
import SliderComponent from './SliderComponent.vue';
import GdprComponent from './GdprComponent.vue';

const Components =
{
	Header: HeaderComponent,
	Footer: FooterComponent,
	ContactForm: ContactFormComponent,
	ReservationForm: ReservationFormComponent,
	ReservationFormBg: ReservationFormBgComponent,
	Slider: SliderComponent,
	GdprSnackComponent: GdprSnackBarComponent,
	AboutCaption: AboutUsCaptionComponent,
	ServicesCaption: OurServicesCaptionComponent,
	ContactCaption: ContactUsCaptionComponent,
	ServicesExpandable: OurServicesExpComponent,
	Fab: FloatingActionButtonComponent,
	GdprComponent: GdprComponent
};

export default Components;